import { enableWrite } from "../common/auth/token_storage";
import { parseQuery } from "../common/url/parse_query";
import {
  ERR_INVALID_AUTH,
  ERR_INVALID_HTTPS_TO_HTTP,
  ERR_TAUI_HOST_REQUIRED,
} from "../websocket/error";
import {
  PasswordResetParams,
  TokenRequest,
  TokenResponse,
} from "../interfaces/openapi/identity-api";
import { getLocalSelectedTenant, getLocalTenants } from "../util/taui-tenant";

export const tauiUrl = `${location.protocol}//${location.host}`;

export interface AuthUrlSearch {
  code?: string;
  token_type?: string;
  expires_in?: number;
  scope?: string;
  state?: string;
  error?: string;
  error_description?: string;
}

export interface AuthData extends TokenResponse {
  access_token: string;
  expires_at: number;
  expires_in: number;
  grant_type: string;
  id_identity: number;
  refresh_token: string;
  refresh_token_expires_at: number;
  refresh_token_expires_in: number;
  token_type: string;

  tauiUrl: string;
  expires: number;
  clientId: string | null;
}

export type SaveTokensFunc = (data: AuthData | null) => void;
export type LoadTokensFunc = () => Promise<AuthData | null | undefined>;

export type GetAuthOptions = {
  tauiUrl?: string;
  clientId?: string | null;
  redirectUrl?: string;
  username?: string;
  password?: string;
  token?: string;
  saveTokens?: SaveTokensFunc;
  loadTokens?: LoadTokensFunc;
};

interface OidcTokenRequest extends TokenRequest {
  grant_type: "oidc";
  code: string;
  provider: string;
  flow: string;
  id_device: string;
}

interface RefreshTokenRequest extends TokenRequest {
  grant_type: "refresh_token";
  refresh_token: string;
}

interface PasswordTokenRequest extends TokenRequest {
  grant_type: "password";
  username: string;
  password: string;
  id_device: string;
}

export const genExpires = (expires_in: number): number =>
  expires_in * 1000 + Date.now() - (expires_in * 1000 > 30000 ? 30000 : 0);

function redirectAuthorize(url: string) {
  document.location!.href = `${url}/${
    __DEMO__ ? "authorize.html" : "auth/authorize"
  }${location.search}${location.hash}`;
}

export function redirectAuthorizeError(url: string, message: string) {
  document.location!.href = `${url}/${
    __DEMO__ ? "authorize.html" : "auth/authorize"
  }${message ? `?error=${message}` : ""}`;
}

async function tokenRequest(
  url: string,
  clientId: string | null,
  data: PasswordTokenRequest | RefreshTokenRequest | OidcTokenRequest
) {
  // Browsers don't allow fetching tokens from https -> http.
  // Throw an error because it's a pain to debug this.
  // Guard against not working in node.
  const l = typeof location !== "undefined" && location;
  if (l && l.protocol === "https:") {
    // Ensure that the url is hosted on https.
    const a = document.createElement("a");
    a.href = url;
    if (a.protocol === "http:" && a.hostname !== "localhost") {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw ERR_INVALID_HTTPS_TO_HTTP;
    }
  }

  const formData: TokenRequest = data;
  if (clientId !== null) {
    formData.client_id = `adminui_${clientId}`;
  }

  const resp = await fetch(`${url}/api/identity/oauth/token`, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
    body: JSON.stringify(formData),
  });

  const response = await resp.json();

  if (!resp.ok)
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw resp.status === 401 /* auth invalid */
      ? ERR_INVALID_AUTH
      : new Error("Unable to fetch tokens");

  const tokens: AuthData = response;
  tokens.tauiUrl = url;
  tokens.clientId = clientId;
  tokens.expires = genExpires(tokens.expires_in);
  return tokens;
}

export async function resetPassword(url: string, data: PasswordResetParams) {
  const resp = await fetch(`${url}/api/identity/identity/password`, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
    body: JSON.stringify(data),
  });

  const response = await resp.json();

  if (!resp.ok) {
    throw resp.status === 401 || resp.status === 400 /* auth invalid */
      ? response.error
      : new Error("Unable to reset password");
  }

  return response.result;
}

export class Auth {
  private _saveTokens?: SaveTokensFunc;

  data: AuthData;

  constructor(data: AuthData, saveTokens?: SaveTokensFunc) {
    this.data = data;
    this._saveTokens = saveTokens;
  }

  get tauiUrl() {
    return this.data.tauiUrl;
  }

  get accessToken() {
    return this.data.access_token;
  }

  get refreshToken() {
    return this.data.refresh_token;
  }

  get idIdentity() {
    return this.data.id_identity;
  }

  get idSession() {
    return this.data.id_session;
  }

  get expired() {
    return Date.now() > this.data.expires;
  }

  /**
   * Refresh the access token.
   */
  async refreshAccessToken() {
    if (!this.data.refresh_token) throw new Error("No refresh_token");

    const data = await tokenRequest(this.data.tauiUrl, this.data.clientId, {
      grant_type: "refresh_token",
      refresh_token: this.data.refresh_token,
    });
    // Access token response does not contain refresh token.
    data.refresh_token = this.data.refresh_token;
    this.data = data;
    if (this._saveTokens) this._saveTokens(data);
  }

  /**
   * Revoke the refresh & access tokens.
   */
  async revoke() {
    if (!this.data.refresh_token) throw new Error("No refresh_token to revoke");

    // There is no error checking, as revoke will always return 200
    await fetch(
      `${this.data.tauiUrl}/api/identity/session/${this.data.id_session}`,
      {
        method: "DELETE",
        credentials: "same-origin",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `Bearer ${this.data.access_token}`,
        }),
      }
    );

    if (this._saveTokens) {
      this._saveTokens(null);
    }
  }
}

export async function getAuth(options: GetAuthOptions = {}): Promise<Auth> {
  let data: AuthData | null | undefined;

  let optionsTauiUrl = options.tauiUrl;
  // Strip trailing slash.
  if (optionsTauiUrl && optionsTauiUrl[optionsTauiUrl.length - 1] === "/") {
    optionsTauiUrl = optionsTauiUrl.substr(0, optionsTauiUrl.length - 1);
  }
  const clientId =
    options.clientId !== undefined
      ? options.clientId
      : getLocalSelectedTenant();

  // Use simple login form
  if (!data && options.username && options.password && optionsTauiUrl) {
    data = await tokenRequest(optionsTauiUrl, clientId, {
      username: options.username,
      password: options.password,
      id_device: "TAUI",
      grant_type: "password",
    });

    if (options.saveTokens) {
      options.saveTokens(data);
    }
  }

  // Check if we came back from an authorize redirect
  // from OIDC
  if (!data && optionsTauiUrl) {
    let provider;

    if (location.pathname && location.pathname.startsWith("/oidc/")) {
      provider = location.pathname.split("/")[2];
    }

    const querySearchParams = parseQuery<AuthUrlSearch>(
      location.search.substr(1)
    );

    // Check if we got redirected here from authorize page
    if (provider) {
      if ("code" in querySearchParams) {
        data = await tokenRequest(optionsTauiUrl, clientId, {
          id_device: "TAUI",
          grant_type: "oidc",
          code: String(querySearchParams.code),
          provider: provider,
          flow: "authorization_code",
        });

        if (options.saveTokens) {
          options.saveTokens(data);
          enableWrite();
        }
      }
    }
  }

  // Check for stored tokens
  if (!data && options.loadTokens) {
    data = await options.loadTokens();
  }

  if (data) {
    localStorage.setItem(
      "tenants",
      JSON.stringify([...new Set([...getLocalTenants(), clientId])])
    );

    return new Auth(data, options.saveTokens);
  }

  if (optionsTauiUrl === undefined) {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw ERR_TAUI_HOST_REQUIRED;
  }

  // If no tokens found but a tauiUrl was passed in, let's go get some tokens!
  redirectAuthorize(tauiUrl);

  // Just don't resolve while we navigate to next page
  return new Promise<Auth>(() => {});
}
