// Compat needs to be first import
import "../resources/compatibility";
import html2canvas from "html2canvas";
import { loadTokens, saveTokens } from "../common/auth/token_storage";
import { Auth, getAuth, tauiUrl } from "../data/auth";
import { Connection, createConnection } from "../data/connection";
import { subscribeComponents } from "../data/ws-components";
import { subscribeConfig } from "../data/ws-config";
import { subscribeScopes } from "../data/ws-scopes";
import { subscribeTenants } from "../data/ws-tenants";
import { subscribeUser } from "../data/ws-user";
import { IdentityDTO } from "../interfaces/openapi/identity-api";
import "../resources/array.flat.polyfill";
import "../resources/safari-14-attachshadow-patch";
import { TucanoAdminUI } from "../types";
import {
  ERR_INVALID_AUTH,
  ERR_CANNOT_CONNECT,
  ERR_TAUI_HOST_REQUIRED,
  ERR_INVALID_HTTPS_TO_HTTP,
} from "../websocket/error";
import { SentryConfig } from "../interfaces/openapi/adminui-api";

let Sentry;

declare global {
  interface Window {
    tauiConnection: Promise<{
      auth: Auth;
      conn: Connection;
    }>;
    tauiConnectionReady?: (tauiConnection: Window["tauiConnection"]) => void;
  }
}

const authProm = async () =>
  getAuth({
    tauiUrl: tauiUrl,
    saveTokens,
    loadTokens: () => Promise.resolve(loadTokens()),
  });

const connProm = async (auth) => {
  try {
    const conn = await createConnection({ auth });

    // Clear url if we have been able to establish a connection
    if (location.pathname && location.pathname.startsWith("/oidc/")) {
      history.replaceState(null, "", "/");
    }

    return { auth, conn };
  } catch (err) {
    if (err !== ERR_INVALID_AUTH) {
      throw err;
    }

    // Clear stored tokens.
    saveTokens(null);

    auth = await authProm();

    const conn = await createConnection({ auth });
    return { auth, conn };
  }
};

if (__DEV__) {
  // Remove adoptedStyleSheets so style inspector works on shadow DOM.
  // @ts-ignore
  delete Document.prototype.adoptedStyleSheets;
  performance.mark("taui-start");
}

window.tauiConnection = (authProm() as Promise<Auth>).then(connProm);

// This is set if app was somehow loaded before core.
if (window.tauiConnectionReady) {
  window.tauiConnectionReady(window.tauiConnection);
}

// Start fetching some of the data that we will need.
window.tauiConnection
  // eslint-disable-next-line no-empty-pattern
  .then(({ conn }) => {
    const noop = () => {
      // do nothing
    };

    subscribeConfig(conn, async (config) => {
      conn.debug = config?.websocket?.debug;

      sentrySetup(config?.sentry);
    });
    subscribeComponents(conn, noop);
    subscribeUser(conn, noop);
    subscribeTenants(conn, noop);
    subscribeScopes(conn, noop);
  })
  .catch((err) => {
    // eslint-disable-next-line no-console
    console.error(err);

    const translateErr = (error) =>
      error === ERR_INVALID_AUTH
        ? "Invalid auth"
        : error === ERR_CANNOT_CONNECT
        ? "Unable to connect"
        : error === ERR_TAUI_HOST_REQUIRED
        ? "TAUI URL invalid."
        : error === ERR_INVALID_HTTPS_TO_HTTP
        ? `Cannot connect to TAUI instances over "http://".`
        : `Unknown error (${error}).`;

    document.location!.href = `${tauiUrl}/${
      __DEMO__ ? "authorize.html" : "auth/authorize"
    }?error=${err?.message || translateErr(err)}`;
  });

const sentrySetup = async (sentryConfig?: SentryConfig) => {
  if (sentryConfig?.enabled) {
    const [sentryBrowser, sentryTracing] = await Promise.all([
      import("@sentry/browser"),
      import("@sentry/tracing"),
    ]);

    Sentry = sentryBrowser;

    Sentry.init({
      enabled: Boolean(sentryConfig?.enabled),
      dsn: sentryConfig.dsn,
      environment: __DEV__ ? "development" : sentryConfig.environment,
      release: __DEV__ ? "development" : __VERSION__,
      attachStacktrace: true,
      debug: __DEV__ || sentryConfig.debug,
      ignoreErrors: [
        // Random plugins/extensions
        "top.GLOBALS",
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        "originalCreateNotification",
        "canvas.contentDocument",
        "MyApp_RemoveAllHighlights",
        "http://tt.epicplay.com",
        "Can't find variable: ZiteReader",
        "jigsaw is not defined",
        "ComboSearch is not defined",
        "http://loading.retry.widdit.com/",
        "atomicFindClose",
        // Facebook borked
        "fb_xd_fragment",
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
        "bmi_SafeAddOnload",
        "EBCallBackMessageReceived",
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        "conduitPage",
        // Generic error code from errors outside the security sandbox
        // You can delete this if using raven.js > 1.0, which ignores these automatically.
        "Script error.",
        // Avast extension error
        "_avast_submit",
        // This seems to get thrown when we get errors in the error handler that are not constructed right
        "Non-Error exception captured",
        // This is firebase connection error, I cannot see a way to handle this gracefully globally
        "Messaging: The notification permission was not granted and blocked instead. (messaging/permission-blocked).",
        // Safari does not yet support firebase and old versions of firefox can kick off
        "Messaging: This browser doesn't support the API's required to use the firebase SDK. (messaging/unsupported-browser).",

        "TypeError: Failed to fetch",
        "TypeError: NetworkError when attempting to fetch resource.",
        "TypeError: Cancelled",
        "TypeError: cancelado",
        "Non-Error promise rejection captured",
        "NotSupportedError",
        "/operation is not supported/",
        "/Loading chunk [d]+ failed/",
        "A mutation operation was attempted on a database that did not allow mutations.",
      ],
      denyUrls: [
        // Google Adsense
        /pagead\/js/i,
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      ],
      integrations: [
        new Sentry.Integrations.GlobalHandlers({
          onerror: false,
          onunhandledrejection: false,
        }),
        new sentryTracing.BrowserTracing(),
      ],
      tracesSampleRate: __DEV__ ? 1 : 0.5,
      beforeSend: (event, hint) => {
        if (window.location.hostname === "localhost") {
          return null;
        }

        // Check if it is an exception
        if (event.exception) {
          event.fingerprint = [
            "{{ default }}",
            event.message ??
              (hint.originalException instanceof Error
                ? hint.originalException.message
                : hint.originalException),
            event.request.url,
          ];

          // return event;

          return html2canvas(document.body, {
            logging: false,
            ignoreElements: (element) => {
              const tagName = element.tagName.toLowerCase();
              return [
                "video",
                "audio",
                "iframe",
                "script",
                "img",
                "canvas",
              ].includes(tagName);
            },
          })
            .then(async (canvas) => {
              const screenshotData = convertDataURIToBinary(
                canvas.toDataURL("image/jpeg", 0.3)
              );
              hint.attachments = [
                { filename: "screenshot.jpg", data: screenshotData },
              ];
              return event;
            })
            .catch(
              () =>
                // We failed to get a screenshot still give us error
                event
            );
        }

        return null;
      },
    });
  }
};

const sentryCall = (e) => {
  if (Sentry) {
    const tauiApp = document.querySelector("taui-app") as any;

    let user: IdentityDTO | null = null;
    if (tauiApp && tauiApp.taui && (tauiApp.taui as TucanoAdminUI).user)
      user = tauiApp.taui.user;

    Sentry.setUser({
      id: user?.idIdentity || null,
      email: user?.email || null,
      username: user?.login || null,
      name: user?.login || null,
    });

    Sentry.captureException(e);
  }
};

window.addEventListener("error", (e) => {
  if (!__DEV__ && e.message === "ResizeObserver loop limit exceeded") {
    e.stopImmediatePropagation();
    e.stopPropagation();
    return;
  }

  // eslint-disable-next-line no-console
  console.error("Error", {
    logger: `frontend.${
      __DEV__ ? "js_dev" : "js"
    }.${__BUILD__}.${__VERSION__.replace(".", "")}`,
    message: `${e.filename}:${e.lineno}:${e.colno} ${e.message}`,
  });

  sentryCall(e);
});

if ("onunhandledrejection" in window) {
  window.addEventListener("unhandledrejection", (e) => {
    // eslint-disable-next-line no-console
    console.warn("WARNING: Unhandled promise rejection", {
      logger: `frontend.${
        __DEV__ ? "js_dev" : "js"
      }.${__BUILD__}.${__VERSION__.replace(".", "")}`,
      message: `${e.reason}`,
    });

    sentryCall(e.reason);
  });
}

function convertDataURIToBinary(dataURI) {
  const base64Index = dataURI.indexOf(";base64,") + ";base64,".length;
  const base64 = dataURI.substring(base64Index);
  const raw = window.atob(base64);
  const rawLength = raw.length;
  const array = new Uint8Array(new ArrayBuffer(rawLength));

  for (let i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
}
