import { io, Socket } from "socket.io-client";
import {
  ERR_CANNOT_CONNECT,
  ERR_TAUI_HOST_REQUIRED,
  ERR_INVALID_AUTH,
} from "./error";
import { Error } from "./types";
import { ConnectionOptions } from "../data/connection";
import * as messages from "./messages";

export const MSG_TYPE_AUTH_REQUIRED = "auth_required";
export const MSG_TYPE_AUTH_INVALID = "auth_invalid";
export const MSG_TYPE_AUTH_OK = "auth_ok";

let DEBUG = false;

let socketTransports: string[] = ["websocket", "polling"]; // use WebSocket first, if available

export interface TauiWebSocket extends Socket {
  tauiVersion: string;
}

export function createSocket(
  options: ConnectionOptions
): Promise<TauiWebSocket> {
  if (!options.auth) {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw ERR_TAUI_HOST_REQUIRED;
  }
  const auth = options.auth;

  DEBUG = options.config?.websocket.debug || DEBUG;

  // Start refreshing expired tokens even before the WS connection is open.
  // We know that we will need auth anyway.
  let authRefreshTask = auth.expired
    ? auth.refreshAccessToken().then(
        () => {
          authRefreshTask = undefined;
        },
        () => {
          authRefreshTask = undefined;
        }
      )
    : undefined;

  const url = auth.tauiUrl;

  if (DEBUG) {
    // eslint-disable-next-line no-console
    console.log("[WebSocket] [Auth phase] Initializing", url);
  }

  function connect(
    triesLeft: number,
    promResolve: (socket: TauiWebSocket) => void,
    promReject: (err: Error) => void
  ) {
    if (DEBUG) {
      // eslint-disable-next-line no-console
      console.log("[WebSocket] [Auth Phase] New connection", url);
    }

    const socket = io(url, {
      path: "/websocket",
      transports: socketTransports,
      withCredentials: true,
    }) as TauiWebSocket;

    // If invalid auth, we will not try to reconnect.
    let invalidAuth = false;

    const closeMessage = () => {
      // If we are in error handler make sure close handler doesn't also fire.
      // socket.io.removeEventListener("close", closeMessage);
      if (invalidAuth) {
        promReject(ERR_INVALID_AUTH);
        return;
      }

      // Reject if we no longer have to retry
      if (triesLeft === 0) {
        // We never were connected and will not retry
        promReject(ERR_CANNOT_CONNECT);
        return;
      }

      const newTries = triesLeft === -1 ? -1 : triesLeft - 1;
      // Try again in a second
      setTimeout(() => connect(newTries, promResolve, promReject), 1000);
    };

    // Auth is mandatory, so we can send the auth message right away.
    const handleOpen = async () => {
      try {
        if (auth.expired) {
          // eslint-disable-next-line no-unneeded-ternary
          await (authRefreshTask ? authRefreshTask : auth.refreshAccessToken());
        }
        socket.emit("auth", messages.auth(auth.accessToken));
      } catch (err) {
        // Refresh token failed
        if (DEBUG)
          // eslint-disable-next-line no-console
          console.log("[WebSocket] [open] Refresh token failed", err);
        invalidAuth = err === ERR_INVALID_AUTH;
        socket.close();
      }
    };

    const handleMessage = async (event: any) => {
      const message = event;

      if (DEBUG) {
        // eslint-disable-next-line no-console
        console.log("[WebSocket] [Auth phase] Received", message);
      }
      switch (message.type) {
        case MSG_TYPE_AUTH_INVALID:
          invalidAuth = true;
          socket.close();
          break;

        case MSG_TYPE_AUTH_OK:
          socket.tauiVersion = message.taui_version;
          promResolve(socket);
          break;

        default:
          if (DEBUG) {
            // We already send response to this message when socket opens
            if (message.type !== MSG_TYPE_AUTH_REQUIRED) {
              // eslint-disable-next-line no-console
              console.warn("[Auth phase] Unhandled message", message);
            }
          }
      }
    };

    socket.on("connect", handleOpen);
    socket.on("message", handleMessage);
    socket.on("disconnect", closeMessage);
    socket.on("error", closeMessage);
    socket.on("connect_error", (err) => {
      if (socketTransports?.[0] === "websocket") {
        // revert to classic upgrade
        socketTransports = ["polling", "websocket"];
        triesLeft += 1;
        socket.close();
      } else {
        // eslint-disable-next-line no-console
        console.error(`connect_error due to ${err.message}`);
        triesLeft = 0;
      }

      closeMessage();
    });
  }

  return new Promise((resolve, reject) =>
    // eslint-disable-next-line no-promise-executor-return
    connect(options.setupRetry, resolve, reject)
  );
}
