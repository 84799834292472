import { TauiComponents } from "../types/config";
import { Connection } from "./connection";
import { createCollection } from "../websocket/collection";

const fetchComponents = (conn) =>
  conn.sendMessagePromise({
    type: "get_components",
    cmd: "get_components",
  });

const subscribeUpdates = (conn, store) =>
  conn.subscribeEvents(
    () =>
      fetchComponents(conn).then((components) =>
        store.setState(components, true)
      ),
    "components_updated"
  );

export const subscribeComponents = (
  conn: Connection,
  onChange: (components: TauiComponents) => void
) =>
  createCollection<TauiComponents>(
    "_components",
    fetchComponents,
    subscribeUpdates,
    conn,
    onChange
  );
